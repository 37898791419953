import type { RouteRecordRaw } from 'vue-router';

import { wrapRuntimeImport } from '@/shared/helpers/async.helpers';
import { makePermissionMeta } from '@/shared/helpers/permissions.helpers';

export const TalkRoutes: RouteRecordRaw[] = [
    {
        path: 'cdr',
        component: wrapRuntimeImport(() => import('./screens/cdr.vue')),
        meta: makePermissionMeta('talk'),
        name: 'talk-cdr'
    },
    {
        path: 'voicemail',
        component: wrapRuntimeImport(() => import('./screens/voicemail.vue')),
        meta: makePermissionMeta('talk'),
        name: 'talk-voicemail'
    },
    {
        path: 'sms',
        component: wrapRuntimeImport(() => import('./screens/sms.vue')),
        meta: makePermissionMeta('talk'),
        name: 'talk-sms'
    },
    {
        path: 'fax',
        component: wrapRuntimeImport(() => import('./screens/fax.vue')),
        meta: makePermissionMeta('talk'),
        name: 'talk-fax'
    },
    {
        path: 'softphone',
        component: wrapRuntimeImport(() => import('./screens/softphone.vue')),
        meta: makePermissionMeta('talk'),
        name: 'talk-softphone'
    }
];
