import { getApiRequestHeaders } from '@/openapi-clients/standard';
import Pusher, { type Channel } from 'pusher-js';
import { onMounted, onUnmounted } from 'vue';

export class PusherService {
    private static pusher?: Pusher;
    static currentAccountId?: string;
    static currentTenantId?: string;
    static accountChannel?: Channel;
    static tenantChannel?: Channel;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    static subscriptionCache: Record<string, Set<(data: any) => void>> = {};

    static connect(accountId: string, tenantId: string) {
        if (this.pusher) {
            if (this.currentAccountId === accountId && this.currentTenantId === tenantId) {
                return;
            }
            this.disconnect();
        }

        this.pusher = new Pusher(import.meta.env.VITE_APP_PUSHER_KEY, {
            cluster: import.meta.env.VITE_APP_PUSHER_CLUSTER,
            channelAuthorization: {
                endpoint: `${import.meta.env.VITE_APP_AUTH_API_URL}/pusher/auth`,
                transport: 'ajax',
                headersProvider: getApiRequestHeaders
            }
        });

        this.accountChannel = this.pusher.subscribe(`private-a-${accountId}`);
        this.tenantChannel = this.pusher.subscribe(`private-t-${tenantId}`);

        this.attachCachedSubscriptions(this.accountChannel);
        this.attachCachedSubscriptions(this.tenantChannel);
    }

    static disconnect() {
        this.pusher?.disconnect();
        this.pusher = undefined;
        this.accountChannel = undefined;
        this.tenantChannel = undefined;
    }

    static updateTenantId(tenantId: string) {
        this.tenantChannel?.unsubscribe();
        this.tenantChannel = this.pusher?.subscribe(`private-t-${tenantId}`);
        this.attachCachedSubscriptions(this.tenantChannel);
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    private static subscribe(event: string, handler: (data: any) => void) {
        this.subscriptionCache[event] = this.subscriptionCache[event] ?? new Set();
        this.subscriptionCache[event].add(handler);
        this.tenantChannel?.bind(event, handler);
        this.accountChannel?.bind(event, handler);
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    private static unsubscribe(event: string, handler: (data: any) => void) {
        this.subscriptionCache[event]?.delete(handler);
        this.tenantChannel?.unbind(event, handler);
        this.accountChannel?.unbind(event, handler);
    }

    private static attachCachedSubscriptions(channel: Channel | undefined) {
        if (!channel) return;
        for (const [event, handlers] of Object.entries(this.subscriptionCache)) {
            for (const handler of handlers) {
                channel.bind(event, handler);
            }
        }
    }

    static createEventChannel<T>(eventName: string) {
        return {
            subscribe(listener: (data: T) => void) {
                PusherService.subscribe(eventName, listener);
            },

            unsubscribe(listener: (data: T) => void) {
                PusherService.unsubscribe(eventName, listener);
            },

            use(listener: (data: T) => void) {
                onMounted(() => this.subscribe(listener));
                onUnmounted(() => this.unsubscribe(listener));
            }
        };
    }

    static get socketId() {
        return this.pusher?.connection.socket_id;
    }
}
