<template>
    <VfModal id="m-auth-tenantSwitcher" ref="modal" scrolls close-on-mask-click>
        <template #header>
            <div class="input-wrapper">
                <i class="far fa-search" />
                <input
                    v-model="searchQuery"
                    v-autofocus
                    type="text"
                    placeholder="Search"
                    class="search"
                    @keydown.up="stepHighlight(-1)"
                    @keydown.down="stepHighlight(1)"
                    @keydown.enter="selectHighligthed"
                />
            </div>
        </template>

        <Loader v-if="!tenants" />
        <ul v-else class="tenant-list">
            <li
                v-for="(tenant, idx) in filteredTenants"
                :key="tenant.id"
                class="tenant"
                :favorited="tenant.isFavorite"
                @click="setTenant(tenant)"
                :class="{ highlight: idx === highlightedIdx }"
            >
                <!-- <i class="far fa-star icon" :class="{ favorited: tenant.isFavorite }" /> -->
                <span class="name">{{ tenant.name }}</span>
            </li>
        </ul>
    </VfModal>
</template>

<script lang="ts" setup>
import { VfModal, showWait, vfModalRef } from '@signal24/vue-foundation';
import { computed, onMounted, ref, watch } from 'vue';

import { GlobalState, type ITenant } from '@/global';
import { type IMspTenantResponse, LoginApi, MspApi } from '@/openapi-clients-generated/auth';
import router from '@/router';
import Loader from '@/shared/components/loader.vue';
import { hasPermission } from '@/shared/helpers/permissions.helpers';
import { withWait } from '@/shared/helpers/request.helpers';
import { Auth } from '@/shared/services/auth.service';
import { dataFrom } from '@signal24/openapi-client-codegen/browser';
import { compact } from 'lodash';

const props = defineProps<{
    callback: () => void;
    autoSwitchToTenantId?: string;
}>();

const modal = vfModalRef();
const searchQuery = ref('');
const highlightedIdx = ref(0);

const activeTenantId = GlobalState.user.value?.tenantId;

const hasMspTenants = computed(() => hasPermission('msp', 'login') || GlobalState.user.value?.mspId || GlobalState.user.value?.isSysAdmin);
const mspTenants = ref<IMspTenantResponse[]>();
const tenants = computed(() => {
    if (hasMspTenants.value && !mspTenants.value) return null;

    const tenants = compact([
        ...GlobalState.user.value!.tenants,
        ...(mspTenants.value?.filter(t => t.id !== activeTenantId).map(t => ({ ...t, isFavorite: false, isAdmin: true, isMspTenant: true })) ?? [])
    ]);

    return tenants.sort((a, b) => {
        if (a.id === activeTenantId) {
            return -1;
        }
        if (b.id === activeTenantId) {
            return 1;
        }

        if (a.isFavorite && !b.isFavorite) {
            return -1;
        }
        if (!b.isFavorite && !a.isFavorite) {
            return 1;
        }

        return a.name.localeCompare(b.name);
    });
});

const filteredTenants = computed(() => {
    const filter = searchQuery.value.trim().toLowerCase();
    return tenants.value?.filter(tenant => tenant.name.toLowerCase().includes(filter));
});

async function loadMspTenants() {
    mspTenants.value = dataFrom(await MspApi.getMspGetMspTenants());
}
onMounted(() => {
    if (props.autoSwitchToTenantId) {
        const tenant = GlobalState.user.value?.tenants.find(t => t.id === props.autoSwitchToTenantId);
        if (tenant) {
            return setTenant(tenant);
        }
    }

    if (hasMspTenants.value) {
        loadMspTenants();
    }
});

function stepHighlight(step: number) {
    highlightedIdx.value = Math.max(0, Math.min(filteredTenants.value!.length - 1, highlightedIdx.value + step));
}

function selectHighligthed() {
    if (filteredTenants.value) {
        setTenant(filteredTenants.value[highlightedIdx.value]);
    }
}
watch(filteredTenants, () => {
    highlightedIdx.value = Math.min(highlightedIdx.value, filteredTenants.value!.length - 1);
});

function setTenant(tenant: ITenant) {
    if (tenant.id === activeTenantId) {
        return props.callback();
    }

    withWait('Switching tenants...', async () => {
        const result = dataFrom(
            await LoginApi.postLoginSwitchTenant({
                body: {
                    tenantId: tenant.id,
                    msp: 'isMspTenant' in tenant
                }
            })
        );

        Auth.processRenewalResponse(result);
        Auth.setTenant(tenant);
        await Auth.loadExistingSession();
        await Auth.reloadPermissions();

        const currentRoute = router.currentRoute.value;
        const matches = currentRoute.fullPath.match(/\/[0-9a-f-]{36}/);
        if (matches) {
            await router.push(currentRoute.fullPath.substring(0, matches.index!));
        }

        props.callback();
    });
}
</script>

<style lang="scss">
#m-auth-tenantSwitcher {
    .vf-modal {
        @apply w-96 h-[75%] rounded-lg;
    }

    .vf-modal-header {
        @apply bg-white p-2 pb-0 text-black;
    }

    .vf-modal-content {
        @apply p-1;
    }
}
</style>

<style lang="scss" scoped>
.input-wrapper {
    flex: 1;

    input {
        flex: 1;
    }
}

.tenant-list {
    @apply m-1;

    .tenant {
        @apply flex items-center space-x-3 p-2 duration-100 rounded-md cursor-pointer;

        &:hover,
        &.highlight {
            @apply bg-blue-50;
        }

        &:hover > .icon {
            @apply opacity-100 text-slate-400;
        }

        .icon {
            @apply opacity-0 text-slate-400 text-sm duration-100 hover:font-bold;

            &.favorited {
                @apply opacity-100 font-bold text-slate-700;
            }
        }

        .name {
            @apply text-gray-900 text-base;
        }
    }
}
</style>
